<template>
  <div>
    <v-row>
      <v-col>
        <v-btn
          small
          dark
          color="primary"
          @click="showDialogNew"
        >
          <v-icon
            left
          >
            mdi-plus
          </v-icon>
          Adicionar contato
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <p v-if="contacts.length <= 0">
          <i>Nenhum contato encontrado</i>
        </p>
        <v-list
          v-else
          two-line
        >
          <template v-for="(contact) in contacts">
            <v-list-item :key="contact.id">
              <v-list-item-content>
                <v-list-item-title>{{ contact.name }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ contact.position || '' }}
                  •
                  {{ contact.sector || '' }}
                  •
                  {{ contact.email || '' }}
                  •
                  {{ contact.telephone || '' }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-hover
                  v-slot="{ hover }"
                >
                  <v-icon
                    small
                    :class="{ 'info--text': hover }"
                    @click="showDialogEdit(contact)"
                  >
                    mdi-pencil
                  </v-icon>
                </v-hover>
                <v-hover
                  v-slot="{ hover }"
                >
                  <v-icon
                    small
                    :class="{ 'error--text': hover }"
                    @click="showDialogDelete(contact)"
                  >
                    mdi-delete
                  </v-icon>
                </v-hover>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list>
      </v-col>
    </v-row>

    <dialog-fields
      :show="showDialogFields"
      :contact="contactEdit"
      :form-validation="formValidation"
      :loading-save="loadingSave"
      @cancel="showDialogFields = false"
      @save="saveContact"
    />

    <dialog-delete
      :show="dialogDelete || loadingDelete"
      :loading="loadingDelete"
      @confirm="confirmDelete"
      @cancel="dialogDelete = false"
    />
  </div>
</template>

<script>
  import customersApi from '@/api/customers'

  export default {

    components: {
      DialogDelete: () => import('@/components/dialog/DialogDelete'),
      DialogFields: () => import('@/views/pages/customers/CustomersContactDialogFields'),
    },

    props: {
      customer: {
        type: Object,
        default: () => {},
      },
    },

    data () {
      return {
        contacts: [],
        contactEdit: null,
        contactDelete: null,
        dialogDelete: false,
        loadingDelete: false,
        showDialogFields: false,
        loadingSave: false,
        formValidation: null,
      }
    },

    mounted () {
      this.loadContacts()
    },

    methods: {
      async loadContacts () {
        try {
          this.loadingGet = true

          const response = await customersApi.listContacts(this.$route.params.id)

          this.contacts = response.data.contacts

          for (const index in this.contacts) {
            if (this.contacts[index].signature) {
              this.contacts[index].signatureBase64 = this.contacts[index].signature
              this.contacts[index].signature = null
            }
          }
        } catch (e) {
          this.$router.push('/customers', () => this.$snackbar.show({ color: 'error', message: this.$apiError._(e) }))
        } finally {
          this.loadingGet = false
        }
      },

      showDialogNew () {
        this.contactEdit = { }
        this.showDialogFields = true
      },

      showDialogEdit (contact) {
        this.contactEdit = { ...contact }
        this.showDialogFields = true
      },

      showDialogDelete (contact) {
        this.contactDelete = contact
        this.dialogDelete = true
      },

      async saveContact (contactFields) {
        try {
          this.loadingSave = true

          const contact = { ...contactFields }

          if (contact.signature) {
            contact.signature = await this.toBase64(contact.signature)
          } else {
            contact.signature = contact.signatureBase64
          }

          let message

          if (contact.id) {
            message = this.$messages._('update_success')
            await customersApi.updateContact(this.customer.id, contact.id, contact)
          } else {
            message = this.$messages._('new_success')
            await customersApi.insertContact(this.customer.id, contact)
          }

          this.showDialogFields = false
          this.$snackbar.show({ color: 'success', message })
          this.loadContacts()
        } catch (e) {
          console.log(e)
          if (e.response.status === 422) {
            this.formValidation = this.$apiError.fieldsValidation(e)
            return
          }

          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingSave = false
        }
      },

      async confirmDelete () {
        try {
          this.loadingDelete = true
          await customersApi.deleteContact(this.customer.id, this.contactDelete.id)
          this.loadContacts()
          this.$snackbar.show({ color: 'success', message: this.$messages._('delete_success') })
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingDelete = false
          this.dialogDelete = false
        }
      },

      async toBase64 (file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        })
      },

    },

  }

</script>
